import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: 'ffbdc9f0ffade5adf3fc7d37e923aae2',
    plugins: [new BugsnagPluginVue()],
  });

  // vue2
  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);

  // vue3
  /* const bugsnagVue = Bugsnag.getPlugin('vue')
Vue.createApp(App)
  .use(bugsnagVue)
  .mount('#app') */
}
