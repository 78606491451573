import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Dashboard',
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/routine',
    name: 'Routine',
    meta: {
      title: 'Routine',
    },
    component: () => import(/* webpackChunkName: "routine" */ '../views/Routine.vue'),
  },
  {
    path: '/testing',
    name: 'Testing',
    meta: {
      title: 'Testing',
    },
    component: () => import(/* webpackChunkName: "testing" */ '../views/Testing.vue'),
  },
  {
    path: '/finance/:month?',
    name: 'Finance',
    meta: {
      title: 'Finance',
    },
    component: () => import(/* webpackChunkName: "finance" */ '../views/Finance.vue'),
  },
  {
    path: '/finance/notion-import',
    name: 'Finance-Notion-Import',
    meta: {
      title: 'Import finances',
    },
    component: () => import(/* webpackChunkName: "finance-notion-import" */ '../views/finance/NotionImport.vue'),
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: {
      title: 'Documents',
    },
    component: () => import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
  },
  {
    path: '/settings',
    name: 'SettingsHome',
    meta: {
      title: 'Settings',
    },
    component: () => import(/* webpackChunkName: "settings-home" */ '../views/settings/Home.vue'),
  },
  {
    path: '/settings/workspaces',
    name: 'SettingsWorkspaces',
    meta: {
      title: 'Workspace settings',
    },
    component: () => import(/* webpackChunkName: "settings-workspaces" */ '../views/settings/Workspaces.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.user.id) {
    await store.dispatch('loadUserData');
    await store.dispatch('workspace/listWorkspaces');
    await store.dispatch('workspace/loadCurrentWorkspace');
    await store.dispatch('initWebsocketConnection');
  }

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = `${to.meta.title} - Kode-hub` || 'Kode-hub';
  });
});

export default router;
