import axios from 'axios';
import { format } from 'date-fns';

export default {
  namespaced: true,

  state: {
    currency: 'Ft',
    currentMonth: format(new Date(), 'y-LL'),
    categories: [
      { text: 'No category', value: -1 },
    ],
    finances: [],
    sum: 0,
    count: 0,
    uniqueCount: 0,
    allFinancesLoading: false,
    loadAllFinances: false,
  },

  actions: {
    async listCategories({ state, rootState }) {
      const categories = await axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/finances/categories/${state.currentMonth}`).then((res) => res.data);
      state.categories = [...[{ text: 'No category', value: -1 }], ...categories];

      console.log(`finance categories loaded for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async saveCategory({ state, rootState }, { categoryId, data }) {
      await axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/finances/category${categoryId ? `/${categoryId}` : ''}`, { ...data, ...{ month: state.currentMonth } });

      console.log(`finance category saved for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async deleteCategory({ rootState }, { categoryId }) {
      await axios.delete(`/workspace/${rootState.workspace.currentWorkspace.id}/finances/category/${categoryId}`);

      console.log(`finance category deleted for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async sortCategories({ rootState }, categories) {
      await axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/finances/categories/sort`, { sort: categories.map((category) => category.id) });

      console.log(`finance categories sorted for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async listFinances({ state, rootState }) {
      if (state.loadAllFinances) {
        state.allFinancesLoading = true;
      }

      const response = await axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/finances/${state.currentMonth}${state.loadAllFinances ? '?all=1' : ''}`).then((res) => res.data);

      state.finances = response.finances;
      state.sum = response.sum;
      state.count = response.count;
      state.uniqueCount = response.unique_count;
      state.allFinancesLoading = false;

      console.log(`finances loaded for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async saveFinance({ rootState }, data) {
      await axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/finance${data.id ? `/${data.id}` : ''}`, data).then((res) => res.data);

      console.log(`finance saved for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async deleteFinance({ rootState }, finance) {
      await axios.delete(`/workspace/${rootState.workspace.currentWorkspace.id}/finance/${finance.id}`).then((res) => res.data);

      console.log(`finance deleted for workspace ${rootState.workspace.currentWorkspace.name}`);
    },

    async getSumOfMonth({ rootState }, { category, month }) {
      const result = await axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/finances/category/${category.value}/month/${month}/sum`).then((res) => res.data);

      console.log(`get sum of finances for category ${category.text} for mont ${month} for workspace ${rootState.workspace.currentWorkspace.name}`);

      return result.sum;
    },
  },
};
