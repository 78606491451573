<template>
  <v-app>
    <v-main>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="cyan"
      />

      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',

  data() {
    return {
      loading: false,
    };
  },

  mounted() {
    axios.interceptors.request.use(
      async (config) => {
        this.loading = true;

        return config;
      },
      (error) => {
        this.loading = false;

        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use((response) => {
      this.loading = false;

      return response;
    }, async (error) => {
      this.loading = false;

      return Promise.reject(error);
    });
  },
};
</script>
