import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import Echo from 'laravel-echo';
import workspace from './modules/workspace';
import finance from './modules/finance';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
      id: null,
      email: null,
      role: null,
      profilePicture: null,
      signUpDate: null,
      lastSignInDate: null,
      hasNotionToken: false,
    },
    settingsBackRoute: null,
    notionImportFinished: false,
  },

  mutations: {
    setSettingsBackRoute(state, route) {
      state.settingsBackRoute = route;
    },
  },

  actions: {
    async loadUserData({ state }) {
      const response = await axios.get('/account').then((res) => res.data);

      state.user.id = response.id;
      state.user.email = response.email;
      state.user.role = response.role;
      state.user.profilePicture = response.profile_picture;
      state.user.signUpDate = response.sign_up_date;
      state.user.lastSignInDate = response.last_sign_in_date;
      state.user.hasNotionToken = response.has_notion_token;
    },

    initWebsocketConnection({ state }) {
      // eslint-disable-next-line global-require
      window.Pusher = require('pusher-js');

      window.Echo = new Echo({
        broadcaster: 'pusher',
        authorizer: (channel) => ({
          authorize: (socketId, callback) => {
            axios.post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name,
            })
              .then((response) => {
                callback(null, response.data);
                console.log('authorized');
              })
              .catch((error) => {
                callback(error);
              });
          },
        }),
        key: process.env.VUE_APP_PUSHER_KEY,
        forceTLS: true,
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      });

      window.Echo.private(`notifications.${state.user.id}`)
        .listen('NotionImportFinished', (data) => {
          console.log('NotionImportFinished');
          console.log(data);
          state.notionImportFinished = true;
        });
    },

    loadDashboard({ rootState }) {
      return axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/dashboard`).then((res) => res.data);
    },

    saveDashboard({ rootState }, { layout }) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/dashboard`, { layout });
    },

    listTasks({ rootState }, { date }) {
      return axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/tasks?date=${date}`).then((res) => res.data);
    },

    listFutureTasks({ rootState }) {
      return axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/tasks/future`).then((res) => res.data);
    },

    sortTasks({ rootState }, data) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/tasks/sort`, { sort: data }).then((res) => res.data);
    },

    countFutureTasks({ rootState }) {
      return axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/tasks/future/count`).then((res) => res.data);
    },

    sortFutureTasks({ rootState }, tasks) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/tasks/future/sort`, tasks.map((task, sort) => ({ id: task.id, sort })));
    },

    saveTask({ rootState }, task) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}${task.id ? `/task/${task.id}` : '/task'}`, task);
    },

    postponeTask({ rootState }, task) {
      if (task.recurring) return Promise.reject();

      return axios.get(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}/postpone`);
    },

    moveTaskToWorkspace(ctx, { task, workspaceId }) {
      return axios.get(`/workspace/${task.workspace_id}/task/${task.id}/move-to/workspace/${workspaceId}`);
    },

    toggleTaskDone({ rootState }, { task, date }) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}/${!task.done ? 'in' : ''}complete`, { date });
    },

    deleteTask({ rootState }, { task, date, type }) {
      return axios.delete(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}`, {
        data: {
          date, type,
        },
      });
    },

    saveChecklistItem({ rootState }, { task, checklistItem }) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}/checklist-item`, checklistItem);
    },

    sortChecklist({ rootState }, { task }) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}/checklist/sort`, { sort: task.checklist.map((item) => item.id) });
    },

    deleteChecklistItem({ rootState }, { task, checklistItem }) {
      return axios.delete(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}/checklist-item/${checklistItem.id}`);
    },

    toggleChecklistItemDone({ rootState }, { task, checklistItem, date }) {
      return axios.post(`/workspace/${rootState.workspace.currentWorkspace.id}/task/${task.id}/checklist-item/${checklistItem.id}/${!checklistItem.done ? 'in' : ''}complete`, { date });
    },
  },

  modules: {
    workspace,
    finance,
  },
});

export default store;
