import axios from 'axios';

export default {
  namespaced: true,

  state: {
    workspaces: [],
    currentWorkspace: null,
  },

  mutations: {
    setCurrentWorkspace(state, workspace) {
      state.currentWorkspace = workspace;
    },

    setWorkspaces(state, data) {
      state.workspaces = data;
    },
  },

  actions: {
    async listWorkspaces({ state }) {
      state.workspaces = await axios.get('/workspaces').then((res) => res.data);
      [state.currentWorkspace] = state.workspaces;
    },

    async save(ctx, workspaceData) {
      await axios.post(`/workspace${workspaceData.id ? `/${workspaceData.id}` : ''}`, workspaceData);
    },

    async delete(ctx, workspaceData) {
      await axios.delete(`/workspace/${workspaceData.id}`);
    },

    async sort({ state }) {
      const data = {};

      state.workspaces.forEach((workspace, index) => {
        data[workspace.id] = index;
      });

      await axios.post('/workspaces/sort', { sort: data });
    },

    async setCurrentWorkspace({ commit }, workspace) {
      commit('setCurrentWorkspace', workspace);

      await axios.put(`/workspace/${workspace.id}/current`);
    },

    async loadCurrentWorkspace({ state, commit }) {
      const response = await axios.get('/workspaces/current').then((res) => res.data);

      // eslint-disable-next-line radix
      commit('setCurrentWorkspace', state.workspaces.find((workspace) => parseInt(workspace.id) === parseInt(response.id)));
    },
  },
};
