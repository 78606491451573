import axios from 'axios';

let accessToken;
const obtainAccessToken = async () => {
  try {
    const response = await axios.create({
      withCredentials: true,
      baseURL: process.env.VUE_APP_BACKEND_HOST,
    }).get('/access-token').then((res) => res.data);

    accessToken = response.token;
    axios.defaults.accessToken = accessToken;
  } catch (e) {
    window.location = process.env.VUE_APP_BACKEND_HOST;
  }
};

// TODO: move to its place
/* const loadScript = (link) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = link;
  script.type = 'text/javascript';
  script.addEventListener('load', resolve);
  script.addEventListener('error', reject);
  document.body.append(script);
});

// TODO: move to its place
const loadCss = (link) => new Promise((resolve, reject) => {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('link');
  style.href = link;
  style.type = 'text/css';
  style.rel = 'stylesheet';
  style.addEventListener('load', resolve);
  style.addEventListener('error', reject);
  head.append(style);
}); */

if (process.env.NODE_ENV === 'development') {
  /* let debugBarResolved = false;
  const pullDebugBarData = async () => {
    if (debugBarResolved) {
      return;
    }

    try {
      const response = await axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_BACKEND_HOST,
      }).get('/__debugbar__').then((res) => res.data);

      // eslint-disable-next-line no-underscore-dangle,no-plusplus
      for (let i = 0; i < response.__debugbar_head__.length; ++i) {
      // eslint-disable-next-line no-underscore-dangle
        if (response.__debugbar_head__[i].script === '') {
        // eslint-disable-next-line no-underscore-dangle
        } else if (response.__debugbar_head__[i].type === 'css') {
        // eslint-disable-next-line no-underscore-dangle,no-await-in-loop
          await loadCss(response.__debugbar_head__[i].script);
        // eslint-disable-next-line no-underscore-dangle
        } else if (response.__debugbar_head__[i].type === 'js') {
        // eslint-disable-next-line no-underscore-dangle,no-await-in-loop
          await loadScript(response.__debugbar_head__[i].script);
        }
      }

      window.jQuery.noConflict(true);
      // eslint-disable-next-line no-underscore-dangle,no-eval
      eval(response.__debugbar__);
    } catch (e) {
    //
    }

    debugBarResolved = true;
  }; */

  /* const requestInterceptor = axios.interceptors.request.use(async (c) => {
    await pullDebugBarData();

    axios.interceptors.request.eject(requestInterceptor);

    return c;
  }); */

  /* axios.interceptors.response.use((response) => {
    if (response.request) {
      window.phpdebugbar.ajaxHandler.handle(response.request);
    }

    return response;
  }); */
}

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    if (!accessToken) {
      await obtainAccessToken();
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
);

axios.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;

    await obtainAccessToken();

    return axios(originalRequest);
  }

  if (error.response.status === 401 && originalRequest.retry) {
    window.location = process.env.VUE_APP_BACKEND_HOST;
  }

  return error;
});
